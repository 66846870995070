body {
  min-width: 320px;
  font-size: 14px;
}

.content {
  padding: 0 1rem;
}


section {
  padding: 4rem 0;
}
.no-padding {
  padding: 0;
}
.no-spacer {
  padding-top: 0;
}

.grey-bg {
  background: #EAEAEA;
}

.pink-bg {
  background: $rose;
}
.darkpink-bg {
  background: #D12868;
  background-size: cover;
  color: $white;
  text-shadow: 0px 0px 1px #333;
}

.peach-bg {
  background: #FFE9DF;
}

.breadcrumb-bar {
  padding: 1rem 0;
  border-bottom: 1px solid #fff;

  .breadcrumbs {
    margin: 0;
  }
}

//section:not(.peach-bg,.masthead,.pink-bg) + section:not(.peach-bg,.pink-bg),
.project-detail section:not(.masthead,.peach-bg) + section:not(.peach-bg,.pink-bg){
  padding-top: 0;
}

.dotted-line-bg {
  background-image: url(/assets/images/dotted-line-swirl@2x.png);
  background-position: center 50px;
  background-size: 90%;
  background-repeat: repeat-x;
}

// .grid-x {
//   margin: 0 0 1rem;
// }

@include breakpoint(medium down) {
  #mainNavigation + section {
    padding-top: 4rem;

    &:not(.masthead,.billboard){
      margin: 0;
      padding-top: 5rem;
    }

  }


}