@include breakpoint(800) { 
    .grid-margin-x>.mw800-8 {
      width: calc(66.66667% - 3.75rem);
    }
    .grid-margin-x>.mw800-4 {
      width: calc(33.33333% - 3.75rem);
    }
  }
  
  .mw300 {
    max-width: 300px;
  }
  .mw400 {
    max-width: 400px;
  }
  .mw500 {
    max-width: 500px;
  }
  
 
  // .mt-0 {
  //   margin-top: 0 !important;
  // }
  // .mt-1 {
  //   margin-top: 1rem !important;
  // }
  // .mt-2 {
  //   margin-top: 2rem !important;
  // }
  // .mt-3 {
  //   margin-top: 3rem !important;
  // }
  
  // .mb-0 {
  //   margin-bottom: 0 !important;
  // }
  // .mb-1 {
  //   margin-bottom: 1rem !important;
  // }
  // .mb-2 {
  //   margin-bottom: 2rem !important;
  // }
  // .mb-3 {
  //   margin-bottom: 3rem !important;
  // }
  
  // .ml-0 {
  //   margin-left: 0 !important;
  // }
  // .ml-1 {
  //   margin-left: 1rem !important;
  // }
  // .ml-2 {
  //   margin-left: 2rem !important;
  // }
  // .ml-3 {
  //   margin-left: 3rem !important;
  // }
  
  // .mr-0 {
  //   margin-right: 0 !important;
  // }
  // .mr-1 {
  //   margin-right: 1rem !important;
  // }
  // .mr-2 {
  //   margin-right: 2rem !important;
  // }
  // .mr-3 {
  //   margin-right: 3rem !important;
  // }
  
  
  // .pt-0 {
  //   padding-top: 0 !important;
  // }
  // .pt-1 {
  //   padding-top: 1rem !important;
  // }
  // .pt-2 {
  //   padding-top: 2rem !important;
  // }
  // .pt-3 {
  //   padding-top: 3rem !important;
  // }
  
  // .pb-0 {
  //   padding-bottom: 0 !important;
  // }
  // .pb-1 {
  //   padding-bottom: 1rem !important;
  // }
  // .pb-2 {
  //   padding-bottom: 2rem !important;
  // }
  // .pb-3 {
  //   padding-bottom: 3rem !important;
  // }
  
  // .pl-0 {
  //   padding-left: 0 !important;
  // }
  // .pl-1 {
  //   padding-left: 1rem !important;
  // }
  // .pl-2 {
  //   padding-left: 2rem !important;
  // }
  // .pl-3 {
  //   padding-left: 3rem !important;
  // }
  
  // .pr-0 {
  //   padding-right: 0 !important;
  // }
  // .pr-1 {
  //   padding-right: 1rem !important;
  // }
  // .pr-2 {
  //   padding-right: 2rem !important;
  // }
  // .pr-3 {
  //   padding-right: 3rem !important;
  // }
  
  
  @include breakpoint(medium up) {
    .medium-mr-0 {
      margin-right: 0 !important;
    }
    .medium-ml-0 {
      margin-left: 0 !important;
    }
  }