footer {
  background-color: #3F4042;
  color: $white;
  font-size: 14px;
  line-height: 28px;
  padding: 3rem 0;
  
  &::after {
    content: '';
    width: 100%;
    height: 5px;
    background: get-color(primary);
    position: fixed;
    bottom: 0;
  }

  a {
    color: $white;
    display: inline-block;
  }
  p {
    font-size: 12px;
  }

  .bottom-bar {
    border-top: 1px solid #979797;
    margin: 2rem 0 0;
    padding: 1rem 0 0;
  }

  .bottom-logo {
    display: inline-block;
    margin: 0 0 1rem;
    width: 100px;
    img {
      max-width: 100%;
    }
  }

  .menu {
    display: block;
    @include breakpoint(small down) {
      margin: 0 0 1rem;
      display: flex;
      justify-content: center;
      margin: 0 1rem 0 0;
      
    }

    list-style: none;
    li {
      margin: 0 0 0 1rem;
    }

    a {
      color: $white;
      display: inline-block;
      padding: 0;
      margin: 0 0 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  
      
  }
  .social-links {
    list-style: none;
    font-size: 36px;
    display: flex;
    margin: 0 0 2rem;

    li {
      margin: 0 0 0 1rem;
      line-height: 0;
      &:first-child {
         margin: 0;
      }
    }
    
  }
  .canada-logo img {
    max-width: 276px;
    width: 100%;
  }
}

