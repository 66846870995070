.image-callout {
  margin: 0 0 2rem;
}

.services {
  margin: 2rem 0 0;
  padding: 0 1rem;

  .service {
    margin: 0 0 1rem;
    img {
      max-width: 120px;
      margin: 0 0 .5rem;
    }
    h3 {
      margin: 0 0 1rem;
      white-space: nowrap;
    }
  }
  
}

.works {
  margin: 2.5rem 0 0;
  .project {
    margin: 2rem 0;
    padding: 0 1rem;

    img {
      max-width: 160px;
    }
    .project-image {
      display: block;
      margin: 0 auto 1rem;
    }
    h3, .h3 {
      display: inline-flex;
      margin: 0 0 1rem;
      min-height: 60px;
      align-items: center;
    }
    h4, .h4 {
      font-size: .95rem;
    }

  }
}