.history-spacer {
  .grid-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: $grey;
      left: 0;
    }
  }
  h2 {
    display: inline-block;
    background: #fff;
    margin: 0;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
  }
}

.numbering {
  display: flex;
  align-items: center;
  margin: 0 0 1rem;
  .number {
    font-weight: 700;
    border: 1px solid;
    background: rgba(255,255,255,.5);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 1.25rem;
    margin: 0 1rem 0 0;
    flex: 0 0 50px;
  }
  h3 {
    margin: 0;
  }
}