.projects {
   
  
  
  
}
.orbit {
    img {
        border: 1px solid;
    }
}
.orbit-controls {
    button {
        background: get-color(primary);
        background: rgba(209, 40, 104, .75);
        border-radius: 100px;

        &.orbit-previous {
            left: -1.25rem;
        }
        &.orbit-next {
            right: -1.25rem;
        }
    }
}
.orbit-bullets button.is-active {
    border: 1px solid rgba(0,0,0,.25);
    box-sizing: content-box;

}