.button {
  min-width: 120px;

  & + .button {
    margin-left: 1rem;
  }
  &.underlined {
    background: transparent;
    border: 0;
    border-bottom: 2px solid rgba($dark-grey, .5);
    color: $dark-grey;
    font-weight: bold;
  }
  &.hollow {

  }

  &.next,
  &.prev {
    background: $white;
    color: #e86493;
    font-family: Lora,serif;
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    @include breakpoint(medium up) {
      width: 200px;
    }
    &::before,
    &::after {
      flex: 0 0 13px;
    }
  }

  &.next {    
    text-align: left;
    &::after {
      content: '';
      background: url('/assets/images/arrow-next-pink@2x.png');
      display: block;
      width: 17px;
      height: 28px;
      background-size: 13px;
      float: right;
      margin: 0  0 0 1rem;
    }
  }

  &.prev {
    text-align: right;
    &::before {
      content: '';
      background: url('/assets/images/arrow-prev-pink@2x.png');
      display: block;
      width: 17px;
      height: 28px;
      background-size: 13px;
      float: left;
      margin: 0 1rem 0 0;
    }
  }
  

}