.billboard {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -75px top;
  position: relative;
  transition: all .5s ease-in-out;

  @include breakpoint(smedium down) {
    .grid-container {
      z-index: 1;
      position: relative;
    }
    &::after {
      content: '';
      background: rgba(255, 255, 255,.8);
  background: linear-gradient(184deg, rgba(255,255,255,0.85) 0%, rgba(232,234,125,.90) 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 0;
    }
  }
    
  @include breakpoint(smedium up) {
    background-position: right -260px top;
  }
  @include breakpoint(medium up) {
    background-position: right -260px top;
  }
  @include breakpoint(700) {
    background-size: contain;
  }
  @include breakpoint(mlarge up) {
    background-position: right -150px top;
  }
  @include breakpoint(xlarge up) {
    background-position: 115% 0;
  }
  @include breakpoint(xxlarge up) {
    background-position: 100% 0;
  }

  .billboard-content {
    transition: all 1s ease-in-out;
    @include breakpoint(smedium down) {
      padding-top: 4rem;
    }
    @include breakpoint(xlarge up) {
      text-align: center;
      justify-content: center;
    }
  }
}



.banner-with-image {
  background-position: center;
  background-size: cover;
  .grid-x {
    height: 200px;
  }
  .button {
    margin: 0;
  }
}

.masthead {
  padding: 0;
  border-bottom: 1px solid #eaeaea;
  .masthead-content {
    flex-direction: column-reverse;
    padding: 1.5rem .5rem 0;
    overflow: hidden;

    @include breakpoint(medium up) {
      flex-direction: row-reverse;
      padding: 0;
      align-items: flex-end;
      min-height: 300px;
    }
  }
  h2 {
    font-weight: 500;
  }
  img {
    margin: 2rem 0 0;
    max-width: none;
    width: 100%;
    &.desktop-placeholder {
      width: 125%;
      @include breakpoint(medium up) {
        width: 150%;
      }
      @include breakpoint(920) {
        width: 100%;
      }
    }

    &.mobile-placeholder {
      
      @include breakpoint(800) {
        width: 80%;
      }
      @include breakpoint(920) {
        width: 65%;
      }
      @include breakpoint(large up) {
        width: 90%;
      }
    }
  }
  &.peach-pink {
    background: rgb(255,191,182);
    background: linear-gradient(180deg, rgba(255,191,182,1) 0%, rgba(255,170,191,1) 100%);
  }

  &.yellow-blue {
    background: rgb(163,216,219);
    background: linear-gradient(184deg, rgba(163,216,219,1) 0%, rgba(232,234,125,1) 100%);
  }

  &.purple-blue {
    background: rgb(184,169,228);
    background: linear-gradient(184deg, rgba(184,169,228,0.7) 0%, rgba(169,221,228,1) 100%);
  }

  &.pink-blue {
    background: rgb(238,174,202);
    background: linear-gradient(184deg, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);  
  }

  &.green-blue {
    background: #00C9FF;
    background: linear-gradient(184deg, #00C9FF 0%, #92FE9D 100%); 
    background: linear-gradient(184deg,#94e8ff 0,rgba(146, 255, 158, .75) 100%);
  }

  &.navy-blue {
    background: #A9DDE4;
    background: linear-gradient(184deg, #b0f6ff 0%, rgba(42, 58, 204, .6) 100%);
  }
 
  // &.navy-blue {
  //   background: #515ada;
  //   background: linear-gradient(90deg, #efd5ff 0%, rgba(91,101,243,.75) 100%);
  // }

  &.pink-orange {
    background: #daae51;
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
  }

  &.yellow-mellow {
    background: #f8ff00;
    background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
  }

  &.red-blue {
    background: #ffc7c9;
    background: linear-gradient(184deg,#ffc7c9 0,#bbe2f7 100%);
  }

}