

.casestudy-block {
    &.peach-bg {
        h3 {
            color: $burgundy;
        }
    }
    .casestudy-header {
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        margin: 0 0 1.5rem;
    }
    .split {
        color: get-color(primary);
        font-family: 'Roboto', sans-serif;
        margin: 0 .5rem;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 2.25rem;
    }

}


