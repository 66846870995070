.infinite-maker {
    position: relative;
    padding-left: 2.25rem;
    &::before {
        content: '';
        background: url(/assets/images/infinity-bullet@2x.png) center;
        background-size: 100%;
        position: absolute;
        left: 0;
        width: 30px;
        height: 25px;
        
    }
}

ul {
    list-style-type: circle;
    margin-left: 1.75rem;
}