.top-bar {
  background: $light-grey;
  padding: 0;
  justify-content: flex-end;
}


.title-bar {
  background: $light-grey;
  padding: 1.5rem 1rem;



  .top-menu-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
  .top-bar {
    background: $white;
  }
  .top-bar ul {
    background: transparent;
  }


}

.top-bar {
  @include breakpoint(small down) {
    display: flex;
  }
}

.top-logo {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  padding: .7rem 0;
  width: 100px;
  position: relative;
  z-index: 100;

  &::before {
    content: '';
    background-image: url(/assets/images/pink-line@2x.png);
  }
  &::after {
    content: '';
    background-image: url(/assets/images/jamd-line-thin@2x.png);
    width: 350px;
    height: 50px;
    position: absolute;
    background-size: 740px;
    left: 89px;
    background-repeat: no-repeat;
    top: 20px;
  }

  @include breakpoint(small down) {
    width: 75px;

    &::after {
      background-size: 505px;
      left: 66px;
      top: 20px;
    }
  }

  @include breakpoint(smedium up) {
    font-size: 15px;
    line-height: 15px;
  }
  @include breakpoint(medium up) {
    padding: .7rem 0;
  }
}


.menu-bar {
  background: $white;
}

@include breakpoint(medium down) {

  .open-menu {
    overflow: hidden;
    #main-menu {
      display: flex !important;
    }
  }
 
}

@include breakpoint(medium up) {
  #mainNavigation + section:not(.masthead) {
    margin-top: -3.25rem;
  }
}
@include breakpoint(large up) {
  #mainNavigation + section:not(.masthead) {
    margin-top: -2.25rem;
  }
}

// .menu-bar,
#mainNavigation {
  background: $white;
  overflow: hidden;

  @include breakpoint(smedium down) {
    background: none;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  @include breakpoint(medium up) {
    padding: 1rem 0;
  }

    
  @include breakpoint(medium down) {
    z-index: 10;

    #main-menu {
      background: $peach;
      display: flex;
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      margin: 0;
      top: 0;
      z-index: 9;
      border-bottom: 5px solid get-color(primary);
      
      .menu {
        background: transparent;
        margin: 0;
        display: inline-flex;
        width: 100%;
        padding: 0 0.5rem 2rem;

        li {
          flex: 0 0;
        }
      }

      a {
        color: get-color(primary);
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        display: inline-block;
        padding: 0;
        position: relative;
        margin: .25rem 0;

        &::after {
          content: '';
          position: absolute;
          width: 5px;
          height: 0px;
          background: #d12868;
          display: block;
          right: -1rem;
          bottom: 10%;
          transition: height .5s;
        }

        &:hover {
          &::after {
            height: 80%;
          }
        }
      }
    }
  }

  

  .logo-cell {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .menu-cell {
    align-items: center;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: auto;
    margin: 0;
    position: relative;
    margin-top: -2rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #d53b74;
      z-index: 10;
      left: 300px;
      bottom: 19px;
    }
    @include breakpoint(medium up) {
      &::after {
        content: '';
        background: $white;
        width: 100%;
        height: 89px;
        position: absolute;
        z-index: 8;
        right: 0;
        top: 0;
      }
    }
    
    li {
      @include breakpoint(small down) {
        flex: 0 50%;
      }
    }
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .menu {
    // background: $white;
    justify-content: flex-end;
    width: 100%;
    margin: 0 0 0 1rem;
    z-index: 100;
    
    @include breakpoint(small down) {
      width: 200px;
      padding: 0 0 0 1rem;
      margin: 0;
    }

    li {
      @include breakpoint(smedium up) {
        margin: 0 .5rem;
      }
      @include breakpoint(medium up) {
        margin: 0 1rem;
      }
      &.active {
        a {
          background: transparent;
          position: relative;
          @include breakpoint(mlarge up) {
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              background: get-color(primary);
              left: 35%;
              bottom: -1.9rem;
              width: 14px;
              height: 14px;
              border-radius: 15px;
              z-index: 10;
              box-shadow: rgba($dark-grey, .5);        
            }
          }
        }
      }
    }
    a {
      color: #020203;
      font-size: 12px;
      padding: .25rem;
      @include breakpoint(medium up) {
        font-size: 15px;
        padding: .25rem;
      }

      &:hover {
        color: $red;
      }
    }
  }
}

.menu-toggle {
  @include hamburger(#d53b74, #000,  25px, 20px, 3px);
  z-index: 100;

  @include breakpoint(small down) {
    margin: 1rem 0 0;
  }
}