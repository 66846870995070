@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@200;400;500;600&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Taviraj:wght@100;200;300;400;500;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap');


h1, .h1 {
    line-height: 2.5rem;
    margin: 0 0 1.5rem;
    span {
        font-weight: 500;
    }
}
h2, .h2 {
    line-height: 1.5rem;
    margin: 0 0 1.5rem;
    span {
        font-weight: 500;
    }
}
h3, .h3 {
    font-weight: 600;
    margin: 0 0 1.5rem;
    span {
        font-weight: 300;
    }
}
h4, .h4 {
    line-height: 1.5rem;
    font-weight: 500;
    margin: 0 0 1.5rem;
}

.w400 {
    font-weight: 400;
}
.w500 {
    font-weight: 500;
}
.w600 {
    font-weight: 600;
}
.w700 {
    font-weight: 700;
}